import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "components/Layout";
import styled from "styled-components";

const Component = props => {
  const image = props.data.file.childImageSharp;
  return (
    <Layout>
      <h1>Duo</h1>
      <Grid>
        <Item>
          <Img fluid={image.fluid} />
        </Item>
        <Item>
          Himmel und Erde, das Weibliche und das Männliche, ​ die Violine und
          das Violoncello: ​ Wohl in keiner anderen Kammermusikbesetzung finden
          sich diese Gegensätze musikalisch unmittelbar vereint. In der
          Reduzierung auf das Wesentliche liegt für Vera und Norbert Hilger die
          Herausforderung ihres gemeinsamen musikalischen Wirkens. Seit beinahe
          25 Jahren ein Paar im Leben und auf der Bühne, entdeckten sie zunächst
          das originale Repertoire für Violine und Violoncello für sich. Die
          Duos von Bohuslav Martinů, Zoltán Kodály und Maurice Ravel, aber auch
          von Ivan Tscherepnin und Hanns Eisler finden sich häufig in ihren
          Konzertprogrammen. Eine besondere Vorliebe der beiden Musiker gilt den
          virtuosen Duetten von Niccolò Paganini und dessen Lehrer Alessandro
          Rolla. Ihre Interpretation dieser Werke ist auf einer CD-Aufnahme aus
          der Chemnitzer Villa Esche zu erleben. Auf der Suche nach Musik aus
          unterschiedlichen Epochen entstand die Idee, Werke anderer Komponisten
          für Violine und Violoncello zu bearbeiten: das Italienische Konzert
          bildete den Auftakt zu einer Reihe von Transkriptionen J. S.
          Bach‘scher Klavierwerke. Ein zweiter Zyklus unter dem Titel „mozart…“
          entstand 2006 und beschäftigt sich mit Musik aus dem Umfeld des
          berühmten Salzburger Komponisten. Hier findet sich auch die Fassung
          des Capriccio fis-Moll von Felix Mendelssohn-Bartholdy für Violine und
          Violoncello. Sämtliche Transkriptionen des Hilgerduos sind als
          Notenausgaben und auf CD präsent. Im Jahr 2008 gründeten die beiden
          Musiker die Julius-Klengel-Akademie, in der Geiger und Cellisten ihre
          musikalische Ausbildung erhalten Eine Idee Norbert Hilgers wurde zur
          Geburtsstunde des Kammerorchesters Akademie Leipzig. Seine
          Transkriptionen der Mozartschen Violinkonzerte KV 208, 216 und 218
          spielte er gemeinsam mit befreundeten Musikern 2010 auf CD ein.
          Innerhalb des Jugendmusiknetzwerkes Clara engagiert sich das
          Musikerpaar, um junge Menschen für ihre Instrumente und die klassische
          Musik zu begeistern und Nachwuchstalente zu förden.
        </Item>
      </Grid>
    </Layout>
  );
};

export default Component;

export const query = graphql`
  query Images {
    file(relativePath: { eq: "hilgerduo.jpg" }) {
      id
      childImageSharp {
        id
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: flex;
  flex-flow: nowrap row;
`;
const Item = styled.div`
  width: 50%;
  padding: 0.5rem;
`;
